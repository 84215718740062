import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Image,
  Row,
} from "react-bootstrap";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import CardIamge1 from "../../assets/images/svg/notfound.png";
import BgPattern1 from "../../assets/images/bg/pattern/hero_bg_pattern.svg";
import { Route, Switch, Link } from "react-router-dom";
import axios from "axios";
import GetProperties from "../getProperties";
import Autocomplete from "react-google-autocomplete";
import validator from "validator";
import Swal from "sweetalert2";
import moment from "moment";
import { Helmet } from "react-helmet";
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import MobileInput from "../../components/common/MobileInput";
import CompanyInput from "../../components/common/CompanyInput";

function OrderValuation() {
  const inputRef = useRef(null);
  const [emailError, setEmailError] = useState("");
  const [btnCheck, setbtnCheck] = React.useState(false);
  const [mobileError, setmobileError] = useState("");
  const [search, setSearch] = React.useState("block");
  const [detail1style, setFormDetail1Style] = React.useState("none");
  const [detail2style, setFormDetail2Style] = React.useState("none");
  const [detail3style, setDetail3Style] = React.useState("none");
  const [images, setImages] = React.useState("");
  const [addressComponent, setAddressComponent] = React.useState([]);
  const loc = new URLSearchParams(window.location.search);
  var address = null;
  var addressComponent1 = [];
  var tmp = 1;
  const all_date = useLocation();
  var address = "";

  const [searchProperty, setSearchProperty] = React.useState({
    address: address,
    method: "new",
    type: "valuation",
  });

  async function set_component(data, address) {
    // setAddressComponent(data);
    // address = all_date.state.address;
    addressComponent1 = data;
    var addpr = {
      address: address,
      method: "new",
    };
    console.log(data);
    setImages(<GetProperties address={addpr} component={data} />);
  }

  const [formData, setFormData] = useState({
    order_report_type: "V",
    place_id: "",
    address: "",
    location_latitude: "",
    location_longitude: "",
    Metro: "",
    Asset: "",
    other: "",
    purpose: "",
    purpose_other: "",
    Estimate: "",
    Frame: "",
    name: "",
    email: "",
    mobile: "",
    firstName: "",
    lastName: "",
    order_type: "N",
    agree: false,
    company_name: "",
    company_id: "",
  });
  const [InputBox, setmyInputBox] = React.useState();
  //

  async function mydetail1(e) {
    e.preventDefault();
  }

  async function mydetail2() {
    console.log(formData);
    if (
      formData.Estimate != "" &&
      formData.Frame != "" &&
      formData.purpose != ""
    ) {
      if (formData.purpose == "Others") {
        if (formData.purpose_other != "") {
          // history.push("/valuation/detail3");
          // setFormDetail2Style("none");
          // setDetail3Style("block");
          return true;
        } else {
          notify("Please Fill All Fields");
        }
      } else {
        // history.push("/valuation/detail3");
        // setFormDetail2Style("none");
        // setDetail3Style("block");
        return true;
      }
    } else {
      notify("Please Fill All Fields");
      return false;
    }
  }

  async function setInputBox(status) {
    console.log(status);
    if (status == 0) {
      console.log(status);
      setmyInputBox();
    } else {
      console.log(status);
    }
  }
  var date = new Date();

  async function validate(evt) {
    var theEvent = evt || window.event;

    if (theEvent.type === "paste") {
      key = window.event.clipboardData.getData("text/plain");
    } else {
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const [loader, set_loader] = useState(false);
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
      setFormData({
        ...formData,
        email: e.target.value,
      });
      setbtnCheck(false);
    } else {
      setEmailError("Invalid");
      // setbtnCheck(true);
    }
  };
  const validateMobile = (e) => {
    var mobile = e.target.value;

    if (mobile && validator.isMobilePhone(mobile, "any")) {
      setmobileError("");
      setFormData({
        ...formData,
        mobile: e.target.value,
      });
      // setbtnCheck(false);
    } else {
      setmobileError("Invalid");
      setbtnCheck(true);
    }
  };

  useEffect(() => {
    console.log(loc.get("type"));
    window.scrollTo(0, 0);
    console.log("data----------->", all_date.state);
    if (all_date.state == undefined) {
      console.log("undefined");
    } else {
      if (tmp == 1) {
        tmp = 0;
        setSearchProperty({
          ...searchProperty,
          address: all_date.state.address,
        });
        set_component(all_date.state.component, all_date.state.address);
        setSearch("none");
        setFormDetail1Style("block");
        setFormDetail2Style("block");
        setDetail3Style("block");
        setFormData({
          ...formData,
          address: all_date.state.address,
          place_id: all_date.state.place_id,
          location_latitude: all_date.state.lat,
          location_longitude: all_date.state.long,
          purpose: loc.type,
        });
      }
    }
  }, []);

  // const history = useHistory();

  const history = useHistory();

  const onClickSearch = () => {
    // history.push("./valuation/detail1");
    setImages(
      <GetProperties address={searchProperty} component={addressComponent} />
    );
    setSearch("none");
    setFormDetail1Style("block");
    setFormDetail2Style("block");
    setDetail3Style("block");
  };
  const [errName, seterrName] = useState("");
  const notify = (message) => toast(message);

  async function add_valuation_report(e) {
    let validate1 = false;
    setFormData({ ...formData, purpose: loc.get("type") });
    if (formData.Metro != "" && formData.Asset != "") {
      if (formData.Asset == "Others") {
        if (formData.other != "") {
          // history.push("/valuation/detail2");
          // setFormDetail1Style("none");
          // setFormDetail2Style("block");
          validate1 = true;
        } else {
          notify("Please Fill All Fields");
          validate1 = false;
        }
      } else {
        // history.push("/valuation/detail2");
        // setFormDetail1Style("none");
        // setFormDetail2Style("block");
        validate1 = true;
      }
    } else {
      notify("Please Fill All Fields");
      validate1 = false;
    }
    if (validate1) {
      let validate2 = false;
      if (
        formData.Estimate != "" &&
        formData.Frame != "" &&
        formData.purpose != ""
      ) {
        if (formData.purpose == "Others") {
          if (formData.purpose_other != "") {
            // history.push("/valuation/detail3");
            // setFormDetail2Style("none");
            // setDetail3Style("block");
            validate2 = true;
          } else {
            notify("Please Fill All Fields");
          }
        } else {
          // history.push("/valuation/detail3");
          // setFormDetail2Style("none");
          // setDetail3Style("block");
          validate2 = true;
        }
      } else {
        notify("Please Fill All Fields");
        validate2 = false;
      }
      console.log(validate1);
      console.log(validate2);
      if (validate2) {
        e.preventDefault();
        console.log(formData);
        // if (formData.name.trim() === "") {
        //     seterrName("Company name is required.")
        //     return false;
        // }

        if (formData.firstName.trim() === "") {
          seterrName("Name is required.");
          return false;
        }
        if (formData.lastName.trim() === "") {
          seterrName("Last name is required.");
          return false;
        }
        if (formData.email === "") {
          seterrName("Email is required.");
          return false;
        }
        if (formData.mobile === "") {
          seterrName("Phone number is required.");
          return false;
        }
        if (!isValidPhoneNumber(formData.mobile)) {
          return false;
        }

        if (formData.agree === false) {
          seterrName("Please accept terms and conditions.");
          return false;
        }
        // if (
        //   formData.company_name.trim() === "" &&
        //   formData.company_id.trim() === ""
        // ) {
        //   seterrName("Company is required.");
        //   return false;
        // }
        set_loader(true);
        var data = {
          order_report_type: formData.order_report_type,
          order_type: formData.order_type,
          place_id: formData.place_id,
          address: formData.address,
          location_latitude: formData.location_latitude,
          location_longitude: formData.location_longitude,
          valuation_property_type: formData.Metro,
          assest_class_type: formData.Asset,
          assest_others_type_description: formData.other,
          purpose_of_valuation: formData.purpose,
          estimate_of_valuation: formData.Estimate,
          time_frame_of_valuation: formData.Frame,
          company_name: formData.name,
          first_name: formData.firstName,
          last_name: formData.lastName,
          email_id: formData.email,
          mobile_number: formData.mobile,
          purpose_of_valuation_description_for_others_option:
            formData.purpose_other,
          company_id: formData.company_id,
          company_name_ref: formData.company_name,
        };
        console.log(data, "=======");
        await axios
          .post(process.env.REACT_APP_NODE_BACKEND_API_URL + "/report", data, {
            headers: {
              Authorization: "vw7kddgha5j30fz1r7ev",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              set_loader(false);
              Swal.fire(
                "Successfully submitted",
                "Admin will contact you shortly.",
                "success"
              ).then((result) => {
                if (result.isConfirmed) {
                  history.push("/");
                }
              });
            }
            setTimeout(() => {
              history.push("/");
            }, 1000);
          })
          .catch((err) => {
            set_loader(false);
            Swal.fire("", err.response.data.message, "warning");
          });
      }
    }
  }

  useEffect(() => {
    setFormData({ ...formData, purpose: loc.get("type") });
  }, [loc.get("type")]);

  return (
    <>
      <Helmet>
        <title
          name="keywords"
          content="comprehensive database of strata reports, building and pest reports, valuations, depreciation reports, and QS reports in one place"
        >
          Save time and money with Propti’s professional property valuation
          reports
        </title>
        <meta
          name="description"
          content="Want to know how much your house is worth? Get a new valuation report or download a pre-existing report with Propti."
        />
      </Helmet>
      {loader === true && (
        <div className="loader-main-div">
          <div className="loader-second">
            <div class="loader"></div>
          </div>
        </div>
      )}
      <div className="d-flex flex-wrap" style={{ minHeight: "100vh" }}>
        <Image
          src={BgPattern1}
          className="position-absolute w-100"
          style={{ zIndex: "-1" }}
          alt="img"
        ></Image>
        <Container>
          <Header />
          {/* <Switch>
                    <Route exact path="/valuation/"> */}
          <div
            className="text-center set_report_title"
            style={{ display: search, position: "relative" }}
          >
            <h1
              className="app-title"
              name="keywords"
              content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more."
            >
              Order valuation
            </h1>
            {/* <div className="px-md-5 set_report_search">
                                <GooglePlaceAutoComplete
                                    value={address}
                                    setSearchProperty = {setSearchProperty} 
                                    searchProperty = {searchProperty}
                                    onClickSearch = {onClickSearch}
                                    placeholder = "Enter the address you need a valuation report for"
                                />
                            </div> */}
            <Row>
              <Col md={12}>
                <Form.Control
                  as={Autocomplete}
                  types={["address"]}
                  componentRestrictions={{ country: "au" }}
                  ref={inputRef}
                  type="text"
                  placeholder="Enter property address"
                  className="contact-form-input starta_search"
                  onPlaceSelected={(place) => {
                    setSearchProperty({
                      ...searchProperty,
                      address: place?.formatted_address,
                    });
                    setFormData({
                      ...formData,
                      address: place?.formatted_address,
                      place_id: place?.place_id,
                      location_latitude: place?.geometry?.location.lat(),
                      location_longitude: place.geometry?.location.lng(),
                    });
                    setAddressComponent(place.address_components);
                  }}
                  onChange={(e) => {
                    setSearchProperty({
                      ...searchProperty,
                      address: e.target.value,
                    });
                    setAddressComponent(e.target.value);
                    setFormData({
                      ...formData,
                      address: "",
                      place_id: "",
                      location_latitude: "",
                      location_longitude: "",
                    });
                  }}
                  required
                ></Form.Control>
              </Col>
              <Button
                className="app-text btn btn-outline-primary  search_button strata_search"
                style={{ borderRadius: "1.5rem", width: "100%", color: "#fff" }}
                onClick={(e) => onClickSearch()}
                disabled={formData.place_id ? false : true}
              >
                Search
              </Button>
              <Col md={12}>
                <p className="description">
                  Mortgage security (first / second) | Family law / Property
                  settlement | Taxation (Stamp Duty / CGT) | Litigation – joint
                  reports and shadow reports | Acquisition & resumption | Rental
                  determinations | Development feasibility | Strata unit
                  entitlement | Asset | Desktop | Insurance Assessment
                </p>
              </Col>
            </Row>
          </div>
          {/* </Route>
                </Switch> */}

          <div className="set_building_title px-3 m-0">
            {/* <Switch>
                        <Route exact path="/valuation/detail1"> */}
            <div style={{ display: detail1style }}>
              <p className="app-title align_center">Order valuation</p>
              <Form className="app-text set-build-form">
                <Row className="align-items-top">
                  <Col md={5} className="image-left-box">
                    {images !== "" || images !== undefined
                      ? images
                      : CardIamge1}
                    <div className="text-center">
                      <p className="strata-img-title">
                        {searchProperty.address}
                      </p>
                    </div>
                  </Col>
                  <Col md={7}>
                    <p className="build-form-title mt-3">Geographic Location*</p>
                    <Row className="mt-3">
                      <Col xs={12} md={6}>
                        <Form.Check
                          inline
                          className="strata-redio"
                          label="Metro"
                          type="radio"
                          id="Metro"
                          name="valuation-radiogroup-1"
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              Metro: e.target.id,
                            })
                          }
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Check
                          inline
                          className="strata-redio"
                          label="Regional / Rural"
                          type="radio"
                          id="Regional"
                          name="valuation-radiogroup-1"
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              Metro: e.target.id,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="my-2 align-items-end">
                      <Col md={12}>
                        <p className="build-form-title mt-3">Asset Class*</p>
                        <Row>
                          <Col xs={12} md={6} className="my-3">
                            <Form.Check
                              inline
                              className="strata-redio"
                              label="Residential"
                              type="radio"
                              id="Residential"
                              name="valuation-radiogroup-2"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  Asset: e.target.id,
                                })
                              }
                              onclick={() => setInputBox(0)}
                            />
                          </Col>
                          <Col xs={12} md={4} className="my-3">
                            <Form.Check
                              inline
                              className="strata-redio"
                              label="Industrial"
                              type="radio"
                              id="Industrial"
                              name="valuation-radiogroup-2"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  Asset: e.target.id,
                                })
                              }
                              onclick={() => setInputBox(0)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6} className="my-3">
                            <Form.Check
                              inline
                              className="strata-redio"
                              label="Commercial"
                              type="radio"
                              id="Commercial"
                              name="valuation-radiogroup-2"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  Asset: e.target.id,
                                })
                              }
                            />
                          </Col>
                          <Col xs={12} md={6} className="my-3">
                            <Form.Check
                              inline
                              className="strata-redio"
                              label="Others"
                              type="radio"
                              id="Others"
                              name="valuation-radiogroup-2"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  Asset: e.target.id,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                      {formData.Asset === "Others" && (
                        <Col md={6}>
                          <input
                            type="text"
                            className="border-0 shadow py-2 px-4 w-100 my-2"
                            placeholder="Enter text here"
                            style={{ borderRadius: "2rem", outline: "none" }}
                            value={formData.other}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                other: e.target.value,
                              })
                            }
                          ></input>
                        </Col>
                      )}
                    </Row>
                    <div style={{ display: detail2style }}>
                      {/* <p className="app-title align_center">Order valuation</p> */}
                      <Form
                        className="app-text set-valuation-form p-0"
                        onSubmit={setFormData}
                      >
                        <p className="build-form-title py-3 mb-0">
                          What is the purpose for you valuation*
                        </p>
                        <Row>
                          <Col xl={6} md={12}>
                            <Form.Check
                              className="set-valu-radio"
                              label="Family law / Property settlement"
                              type="radio"
                              id="Family_Law/Property-Settlement"
                              name="valuation-radiogroup-3"
                              checked={
                                formData.purpose ===
                                "Family_Law/Property-Settlement"
                              }
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  purpose: e.target.id,
                                })
                              }
                            />
                            <Form.Check
                              className="set-valu-radio pt-1"
                              label="Pre-purchase advise"
                              type="radio"
                              id="Pre-purchase-advise"
                              name="valuation-radiogroup-3"
                              checked={
                                formData.purpose === "Pre-purchase-advise"
                              }
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  purpose: e.target.id,
                                })
                              }
                            />
                            <Form.Check
                              className="set-valu-radio"
                              label="Mortgage security (First / Second)"
                              type="radio"
                              id="Mortage-Security(First/Second)"
                              name="valuation-radiogroup-3"
                              checked={
                                formData.purpose ===
                                "Mortage-Security(First/Second)"
                              }
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  purpose: e.target.id,
                                })
                              }
                            />

                            <Form.Check
                              className="set-valu-radio"
                              label="Strata unit entitlement"
                              type="radio"
                              id="Strata-unit-entitlement"
                              name="valuation-radiogroup-3"
                              checked={
                                formData.purpose === "Strata-unit-entitlement"
                              }
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  purpose: e.target.id,
                                })
                              }
                            />
                          </Col>
                          <Col xl={6} md={12}>
                            <Form.Check
                              className="set-valu-radio"
                              label="Taxation (Stamp Duty / CGT)"
                              type="radio"
                              id="Taxation(Stamp_Duty_/_CGT)"
                              name="valuation-radiogroup-3"
                              checked={
                                formData.purpose ===
                                "Taxation(Stamp_Duty_/_CGT)"
                              }
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  purpose: e.target.id,
                                })
                              }
                            />

                            <Form.Check
                              className="set-valu-radio"
                              label="Insurance assesment"
                              type="radio"
                              id="insurance_assesment"
                              name="valuation-radiogroup-3"
                              checked={
                                formData.purpose === "insurance_assesment"
                              }
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  purpose: e.target.id,
                                })
                              }
                            />

                            <Form.Check
                              className="set-valu-radio"
                              label="Desktop"
                              type="radio"
                              id="desktop"
                              name="valuation-radiogroup-3"
                              checked={formData.purpose === "desktop"}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  purpose: e.target.id,
                                })
                              }
                            />

                            <Form.Check
                              className="set-valu-radio"
                              label="Internal advise / audit"
                              type="radio"
                              id="Internal-advise/Audit"
                              name="valuation-radiogroup-3"
                              checked={
                                formData.purpose === "Internal-advise/Audit"
                              }
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  purpose: e.target.id,
                                })
                              }
                            />
                            <div className="set-valu-radio d-flex justify-content-between align-items-center">
                              <Form.Check
                                label="Others"
                                type="radio"
                                id="others"
                                name="valuation-radiogroup-3"
                                checked={formData.purpose === "others"}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    purpose: e.target.id,
                                  })
                                }
                              />
                              {formData.purpose === "others" && (
                                <input
                                  type="text"
                                  placeholder="Enter Text Here"
                                  className="border-0 shadow py-2 w-100 px-4 ml-md-5"
                                  style={{
                                    borderRadius: "2rem",
                                    outline: "none",
                                  }}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      purpose_other: e.target.value,
                                    })
                                  }
                                ></input>
                              )}
                            </div>
                          </Col>
                          <Col xl={6} md={12} className="my-3 fixed_value">
                            <p className="build-form-title py-3 mb-0">
                              Valuation estimate* 
                            </p>
                            <input
                              type="text"
                              maxlength="8"
                              className="border-0 shadow py-2 w-100 px-4"
                              style={{ borderRadius: "2rem", outline: "none" }}
                              placeholder=""
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  Estimate: e.target.value,
                                })
                              }
                              onKeyPress={(e) => validate(e)}
                            ></input>
                            <span class="unit">$</span>
                          </Col>
                          <Col xl={6} md={12} className="my-3 ">
                            <p className="build-form-title py-3 mb-0">
                              Time frame*
                            </p>

                            <Form.Control
                              type="date"
                              placeholder="Select Date"
                              min={moment(date).format("YYYY-MM-DD")}
                              className=" border-0 shadow py-2 w-100 px-4 date_time"
                              style={{ borderRadius: "2rem", outline: "none" }}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  Frame: e.target.value,
                                })
                              }
                              required
                            ></Form.Control>
                          </Col>
                        </Row>
                        {/* <div className="text-center my-5 pt-3">
                  <Link>
                    <Button
                      onClick={() => {
                        setFormDetail2Style("none");
                        setFormDetail1Style("block");
                      }}
                      className="px-5 py-2 next_button"
                      style={{ borderRadius: "2rem", color: "#fff" }}
                    >
                      Go back
                    </Button>
                  </Link>

                  <Button
                    onClick={() => {
                      mydetail2();
                    }}
                    className="px-5 py-2 next_button"
                    style={{ borderRadius: "2rem", color: "#fff" }}
                  >
                    Next
                  </Button>
                </div> */}
                      </Form>
                    </div>
                    {/* </Route> */}
                    {/* <Route path="/valuation/detail3"> */}
                    <div style={{ display: detail3style }}>
                      {/* <p className="app-title align_center">Order valuation</p> */}
                      <Form className="app-text " onSubmit={setFormData}>
                        <Row className="mt-5">
                          {/* <Col md={4}> */}
                          {/* {images}
                    <div className="text-center">
                      <p className="strata-img-title">
                        {searchProperty.address}
                      </p>
                      <p className="strata-img-date">
                        {" "}
                        Price and estimated time will be sent to you via email
                        once order is confirmed
                      </p>
                    </div> */}
                          {/* </Col> */}
                          <Col xl={12} md={12} className="">
                            {/* onSubmit={handleSubmit} */}

                            <FormGroup>
                              <Form.Label className="contact-form-label">
                                Company name*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter your company name here"
                                className="contact-form-input"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    name: e.target.value,
                                  })
                                }
                                required
                              ></Form.Control>
                            </FormGroup>

                            <Row>
                              <Col md={6} className="my-3">
                                <Form.Label className="contact-form-label">
                                  First name*
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter your first name here"
                                  className="contact-form-input"
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      firstName: e.target.value,
                                    })
                                  }
                                  required
                                ></Form.Control>
                              </Col>
                              <Col md={6} className="my-3">
                                <Form.Label className="contact-form-label">
                                  Last name*
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter your last name here"
                                  maxlength="10"
                                  className="contact-form-input"
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      lastName: e.target.value,
                                    })
                                  }
                                  required
                                ></Form.Control>
                              </Col>
                              <Col md={6} className="my-3">
                                <Form.Label className="contact-form-label">
                                  Email*
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Enter your email  here"
                                  className="contact-form-input"
                                  onChange={(e) => validateEmail(e)}
                                  required
                                ></Form.Control>
                                <span className="err">{emailError}</span>
                              </Col>
                              <Col md={6} className="my-3">
                                <Form.Label className="contact-form-label">
                                  Mobile number*
                                </Form.Label>
                                {/* <Form.Control
                            type="text"
                            placeholder="Enter your mobile number here"
                            maxlength="10"
                            className="contact-form-input"
                            onChange={(e) => validateMobile(e)}
                            onKeyPress={(e) => validate(e)}
                            required
                          ></Form.Control> */}

                                <MobileInput
                                  value={formData.mobile}
                                  onChange={(e) =>
                                    setFormData({ ...formData, mobile: e })
                                  }
                                />
                                <span className="err">
                                  {formData.mobile !== ""
                                    ? formData.mobile &&
                                      isPossiblePhoneNumber(formData.mobile)
                                      ? formData.mobile &&
                                        isValidPhoneNumber(formData.mobile)
                                        ? ""
                                        : "Invalid Phone Number"
                                      : "Impossible Phone Number"
                                    : ""}
                                </span>
                              </Col>
                            </Row>
                            <CompanyInput
                              formData={formData}
                              setFormData={setFormData}
                            />
                            <Form.Check
                              className="agree-checkbox my-3 app-text"
                              name="purchage-agree"
                              label="I agree to terms and conditions*"
                              type="checkbox"
                              id="agree"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  agree: e.target.checked,
                                })
                              }
                            ></Form.Check>
                            <p style={{ color: "red" }} className="text-center">
                              {" "}
                              {errName}{" "}
                            </p>
                            <Row className="justify-content-center">
                              <Button
                                onClick={() => {
                                  setSearch("block");
                                  setFormDetail1Style("none");
                                  setFormDetail2Style("none");
                                  setDetail3Style("none");
                                }}
                                className="navigation_button app-text"
                                style={{
                                  borderRadius: "1.5rem",
                                  width: "100%",
                                }}
                              >
                                Go back
                              </Button>

                              <Button
                                className="navigation_button app-text"
                                style={{
                                  borderRadius: "1.5rem",
                                  width: "100%",
                                }}
                                onClick={(e) => add_valuation_report(e)}
                                disabled={btnCheck}
                              >
                                Order now
                              </Button>
                            </Row>
                            {/* <p className='text-center' style={{ margin: "20px 0", color: "red" }}>Admin will contact you shortly*</p> */}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>

                <Row className="my-2 align-items-center">
                  <Col md={4}></Col>
                </Row>
                {/* <div className="text-center next-btn-block">
                  <Link
                    onClick={() => {
                      setSearch("block");
                      setFormDetail1Style("none");
                    }}
                  >
                    <Button
                      type="submit"
                      className="px-5 py-2 next_button"
                      style={{ borderRadius: "2rem", color: "#fff" }}
                    >
                      Go back
                    </Button>
                  </Link>

                  <Button
                    onClick={(e) => {
                      mydetail1(e);
                    }}
                    type="submit"
                    className="px-5 py-2 next_button"
                    style={{ borderRadius: "2rem", color: "#fff" }}
                  >
                    Next
                  </Button>
                </div> */}
              </Form>
            </div>
            {/* </Route> */}
            {/* <Route path="/valuation/detail2"> */}

            {/* </Route>
                    </Switch> */}
            <ToastContainer toastClassName="border border-grey" />
          </div>
        </Container>
        <Footer />
        {/* <div className="w-100 footer-bottom-line mt-5">

            </div>  */}
      </div>
    </>
  );
}

export default OrderValuation;
